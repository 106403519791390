import React from 'react'

const PaymentLinkContext = React.createContext({
  loading: false,
  setLoading: () => {}
})

const PaymentLinkProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(true)
  return (
    <PaymentLinkContext.Provider
      value={{
        loading,
        setLoading
      }}
    >
      {children}
    </PaymentLinkContext.Provider>
  )
}

export { PaymentLinkProvider, PaymentLinkContext }
