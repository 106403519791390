//** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Actions & Store
import themeConfig from '@configs/themeConfig'

const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const [skin, setSkin] = useState(() => {
    try {
      // ** Get from local storage by key
      const item = localStorage.getItem('conciliaPOS.skin')
      // ** Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : themeConfig.layout.skin
    } catch (error) {
      // ** If error also return initialValue
      console.log(error)
      return themeConfig.layout.skin
    }
  })

  // ** Return a wrapped version of useState's setter function
  const setTheme = (value) => {
    try {
      // ** Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(skin) : value
      // ** Save to store & local storage
      setSkin(valueToStore)
      window.localStorage.setItem(
        'conciliaPOS.skin',
        JSON.stringify(valueToStore)
      )
    } catch (error) {
      // ** A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  // when user change theme, it will not persist in local storage.
  // example: payment link (only white theme), but when user back to platform, it will be the last theme
  const setNotPersistTheme = (value) => {
    try {
      setSkin(value)
    } catch (error) {
      // ** A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  useEffect(() => {
    // ** Get Body Tag
    const element = window.document.body

    // ** Define classnames for skins
    const classNames = {
      dark: 'dark-layout',
      bordered: 'bordered-layout',
      'semi-dark': 'semi-dark-layout'
    }

    // ** Remove all classes from Body on mount
    element.classList.remove(...element.classList)

    // ** If skin is not light add skin class
    if (skin !== 'light') {
      element.classList.add(classNames[skin])
    }
  }, [skin])

  const theme = skin
  return (
    <ThemeContext.Provider value={{ theme, setTheme, setNotPersistTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, ThemeContext }
