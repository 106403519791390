import axios from 'axios'
import { cloneDeep } from 'lodash'
import moment from 'moment'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
}

// ** Converts HTML to string
export const htmlToString = (html) => {
  return html?.replace(/<\/?[^>]+(>|$)/g, '')
}

export const removeEmptyProperties = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => {
      if (v !== null || v !== '' || v !== undefined) {
        return v
      }
    })
  )
}
export const removeEmptyPropertiesAddDash = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (value === null || value === '' || value === undefined) {
        return [key, '-']
      }
      return [key, value]
    })
  )
}

export const removeEmptyPropertiesAddDashToArray = (array) => {
  return array.map((item) => removeEmptyPropertiesAddDash(item))
}

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export function convertObjectToFormData(object) {
  return Object.entries(object).reduce(
    (key, value) => (key.append(...value), key),
    new FormData()
  )
}

/**
 * Get a date in format `yyyy-mm-dd 00:00:00`
 * from a date in format `dd/mm/yyyy`
 *
 * @param {string} frontendDate - dd/mm/yyyyy
 */
export function getBackendDate(frontendDate, time = true) {
  if (!frontendDate || typeof frontendDate !== 'string') {
    console.error('You need to provide a valid date string format')
    return ''
  }

  const dataArr = frontendDate.split('/')
  if (time) {
    return `${dataArr[2]}-${dataArr[1]}-${dataArr[0]} 00:00:00`
  }
  return `${dataArr[2]}-${dataArr[1]}-${dataArr[0]}`
}

export function formatStringDate(dateString) {
  const pattern = /(\d{4})\-(\d{2})\-(\d{2})/
  if (!dateString || !dateString.match(pattern)) {
    return null
  }

  return dateString.replace(pattern, '$3/$2/$1')
}

export function formatCpf(cpf) {
  if (!cpf) {
    return null
  }

  cpf = cpf.replace(/[^\d]/g, '')

  if (cpf.length > 11) {
    return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
}
export function formatFloat(number) {
  return new Intl.NumberFormat('', { maximumSignificantDigits: 2 }).format(
    number
  )
}

export function maxLengthCheck(object) {
  const maxLength = 4
  if (object) {
    if (object.value?.length > 4) {
      object.value = object.value.slice(0, maxLength)
    }
  }
}

export function objectMap(object, mapFn) {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key])
    return result
  }, {})
}

export const clearStorageUser = () => {
  let i = 1

  localStorage.removeItem('conciliaPOS.UserData')
  localStorage.removeItem('conciliaPOS.Session')

  while (JSON.parse(localStorage.getItem(`conciliaPOS.UserAbility${i}`))) {
    localStorage.removeItem(`conciliaPOS.UserAbility${i}`)
    i++
  }
}

export const getUserSession = () =>
  JSON.parse(localStorage.getItem('conciliaPOS.Session'))

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('conciliaPOS.UserData')
export const getUserData = () => {
  let i = 1

  let abillities = []

  while (JSON.parse(localStorage.getItem(`conciliaPOS.UserAbility${i}`))) {
    const storageAbility = JSON.parse(
      localStorage.getItem(`conciliaPOS.UserAbility${i}`)
    )
    abillities = [...abillities, ...storageAbility]
    i++
  }

  const userData =
    typeof localStorage.getItem('conciliaPOS.UserData') === 'string'
      ? JSON.parse(localStorage.getItem('conciliaPOS.UserData'))
      : localStorage.getItem('conciliaPOS.UserData')

  if (userData) {
    userData.ability = abillities?.map((item) => ({
      ...item,
      subject: item.subject.toLowerCase().replaceAll(' ', '-')
    }))
  }

  return userData
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7D45DC', // for selected option bg-color
    neutral10: '#7D45DC', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const storageItem = (type, name, value) => {
  let toReturn
  if (type === 'set') {
    window.localStorage.setItem(name, JSON.stringify(value))
  } else if (type === 'get') {
    if (window.localStorage.getItem(name)) {
      if (typeof window.localStorage.getItem(name) === 'string') {
        try {
          toReturn = JSON.parse(window.localStorage.getItem(name))
        } catch (e) {
          toReturn = window.localStorage.getItem(name)
        }
      } else {
        toReturn = window.localStorage.getItem(name)
      }
    }
  } else if (type === 'remove') {
    window.localStorage.removeItem(name)
  }
  return toReturn
}

// function chunkArrayInGroups(arr, size) {
//   if (arr) {
//     const myArray = []
//     for (let i = 0; i < arr?.length; i += size) {
//       myArray?.push(arr?.slice(i, i + size))
//     }
//     return myArray
//   }
//   return []
// }

export const gerenateRandomColor = () => {
  const arrayColors = [
    'light-info',
    'light-warning',
    'light-success',
    'light-primary',
    'light-danger'
  ]

  const randomColorIndex = Math.floor(Math.random() * arrayColors.length)
  return arrayColors[randomColorIndex]
}

export const saveLocalStorageUserData = (data, accessToken) => {
  // const responseAbility = data?.user?.profile?.permissions?.map(
  //   (permission) => {
  //     const action = []

  //     if (permission?.create === 1) {
  //       action.push('create')
  //     }

  //     if (permission?.read === 1) {
  //       action.push('read')
  //     }

  //     if (permission?.update === 1) {
  //       action.push('update')
  //     }

  //     if (permission?.delete === 1) {
  //       action.push('delete')
  //     }

  //     return {
  //       action,
  //       subject: permission?.permission?.name
  //         ?.toLowerCase()
  //         ?.replaceAll(' ', '-')
  //     }
  //   }
  // )

  const formattedUserData = {
    ...data?.usuario
    // ability: responseAbility || []
  }

  // const chunckedAbility = chunkArrayInGroups(responseAbility, 20) || []

  // let i = 1

  // for (const chunck of chunckedAbility) {
  //   storageItem('set', `conciliaPOS.UserAbility${i}`, chunck)
  //   i++
  // }

  const userToStorage = cloneDeep(formattedUserData)
  if (userToStorage?.ability) {
    delete userToStorage?.ability
  }

  if (userToStorage?.profile?.permissions) {
    delete userToStorage?.profile?.permissions
  }

  storageItem('set', 'conciliaPOS.UserData', userToStorage)
  if (accessToken) {
    storageItem('set', 'conciliaPOS.Session', data?.access_token)
  }
  return formattedUserData
  // return responseAbility
}

export const formatCurrency = (value, locale = 'pt-BR', currency = 'BRL') => {
  const formatting = { style: 'currency', currency }

  return new Intl.NumberFormat(locale, formatting).format(value)
}

export const downloadXLSFile = async (dataUrl, fileName) => {
  // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
  // add bearer token if needed

  const token = getUserSession()
  const headers = { 'Content-Type': 'blob' }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method: 'GET',
    url: dataUrl,
    responseType: 'arraybuffer',
    headers
  }

  try {
    const response = await axios(config)

    const outputFilename = fileName || `conciliaPOS_${moment().format()}.xlsx`

    const url = URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', outputFilename)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    throw Error(error)
  }
}
